.body {
    height: 85vh;
    padding: 30px 5% 30px 5%;
    border: 5px;
}

.App {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: auto;
  }

.window {
    background: white;
    overflow: scroll;
    height: 100%;
    border: 5px solid;
    border-color: black;
    box-shadow: 10px 9px 0px rgb(46 46 46 / 98%);
}

@media screen and (max-width: 700px) {
    .body {
        padding: 20px 5% 35px 5%;
    }
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2; /* Ensure it's below the content */
    opacity: 0; /* Initially hide the video */
    transition: opacity 0.5s ease; /* Add a transition for smoother fade-in */
}

.background-video.loaded {
    opacity: 1; /* Show the video once loaded */
}

.placeholder-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./temp.png');
    background-size: cover;
    z-index: -1; /* Ensure it's above the video */
}