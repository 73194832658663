@font-face {
    font-family: 'pxlxxl';
    src: local('pxlxxl'), url(../fonts/pxlxxl.ttf) format('truetype');
  }


/* Navbar container */
.navbar {
    background-color: white;
    font-family: "pxlxxl";
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 9px 0px rgb(46 46 46 / 100%);
  }

  .home {
    cursor: pointer;
  }
  
  /* Links inside the navbar */
  .navbar a {
    font-size: 24px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  .misc {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .date {
    font-size: 24px;
    margin-right: 14px;
  }

.circles {
    display: flex;
    margin-right: 14px;
}

  .circle {
    transition: 0.3s;
    width: 24px;
    height: 24px;
    margin-right: 0px;
    margin-left: 12px;
    border-radius: 12px;
    cursor: pointer;
  }

  .orange {
    background-color: #f37725;
  }

  .black {
    background-color: rgb(40, 39, 37);
  }

  .blue {
    background-color: #6DB3F2;
  }

  .circle:hover {
    transform: scale(1.1);
  }
  
  /* The dropdown container */
  .dropdown {
    float: left;
  }
  
  /* Dropdown button */
  .dropdown .dropbtn {
    font-size: 48px;
    border: none;
    outline: none;
    color: rgb(40, 39, 37);
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }
  
  /* Add a red background color to navbar links on hover */
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: rgb(40, 39, 37);
    color: white;
  }
  
  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  @media screen and (max-width: 702px) {

    .dropdown .dropbtn{
      font-size: 7.5vw;
      padding: 20px 7px;
    }
    .date {
      display: none;
    }
    .circle {
      width: 20px;
      height: 20px;
      margin-left: 6px;
    }
}

@media screen and (max-width: 375px) {

  .dropdown .dropbtn{
    font-size: 6vw;
  }
}