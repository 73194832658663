@font-face {
    font-family: 'pxlxxl';
    src: local('pxlxxl'), url(../fonts/pxlxxl.ttf) format('truetype');
  }

.homePage {
    background-image: url("banner-dunes.jpeg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    font-family: "pxlxxl";
    font-size: 80px;
    text-align: center;
    background-position: center center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.homeBanner {
    display: flex;
    justify-content: space-between;
    padding: 40px 25% 40px 25%;
    align-items: center;
}

.profile {
    height: 200px;
    border: 5px solid;
    border-color: black;
    box-shadow: 10px 9px 0px rgb(46 46 46 / 98%);
    border-radius: 125px;
}

.wyatt {
    font-size: 100px;
}

.developer {
    color: #7676bf;
}

.fullstack {
    color: white;
    font-size: 60px;
    margin-bottom: 60px;
    padding: 0 2% 0 2%;

}

@media screen and (max-width: 702px) {
    .homeBanner{
        flex-direction: column;
        padding: 20px 10% 70px 10%;
    }
    .wyatt {
        font-size: 80px;
        margin-bottom: 20px;
    }
    .profile{
        height: 150px;
    }
}
