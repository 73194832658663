

.walmart {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 1000px;
    font-size: 80px;
    text-align: center;
}

